<template>
  <div v-if="this.$store.state.loggedIn" id="hide">
    
      <h3>{{ cName }}</h3>
      <v-select :options="types"/> -> <v-select :options="types"/>
    <div v-if="beers.length > 0" id="hide">
      <table border="0" width="90%" class="center">
        <tr><th>Id</th><th>Beers</th></tr>
        <tr v-for="item in orderedBeers" :key="item.beerName">
            <td>{{item.beerId}}</td><td>{{item.beerName}} [{{item.abv}}]</td>
            <td v-if="cellarType=='UNKNOWN'">{{item.dateAdded | formatDate}}</td>
            <td v-else-if="cellarType=='YEAR_VINTAGE'||cellarType=='YEAR_ANNIVERSARY'||cellarType=='YEAR_FROM_FIRST'">{{item.exitDate | formatDate}}</td>
            <td v-else>{{item.exitOrder}}</td>
        </tr>
      </table>
      </div>
    <div v-else>No beers.</div>
      <br />
      <input v-model="beerId" placeholder="" /><button
        v-on:click="addBeer"
      >
        Add Beer
      </button>
      </div>
</template>

<style>
.v-select{
   width: 30%;
}
</style>

<script>
import moment from 'moment'
import _ from 'lodash';

export default {
  data() {
    return {
      cellar:{
          Name: "Unknown",
      },
      beers: [],
      cellarType: "",
      beerId: 0,
      exitCellar: "",
      config: {
        placeholder: "blah",
        options: [{value:"YEAR_VINTAGE"}, {value:"RANDOM_WEEKDAYS"},{value: "YEAR_ANNIVERSARY"}, {value:"RANDOM_WEEKENDS"}, {value:"YEAR_FROM_FIRST"}],
        width: 300,
      },
      types: ["YEAR_VINTAGE", "RANDOM_WEEKDAYS"],
      configExit: {
        placeholder: "exit",
        options: [{value:"Stash"}, {value: "Deck"}],
        width: 300,
      },
    };
  },
  
  computed: {
    orderedBeers: function() {
      if (this.cellarType == 'YEAR_VINTAGE') {
        return _.orderBy(this.beers, 'exitDate')
      } else  {
        return _.orderBy(this.beers, 'exitOrder')
      }
    },

  },
  filters: {
    formatDate: function(value) {
  if (value) {
    return moment.unix(value).format('DD/MM/YYYY')
  }
}
  },
  components: {
  },
  props: {
    cName: String,
  },
  beforeMount() {
    this.getCellar(this.cName);
  },
  methods: {
     async addBeer() {
      this.loading = true;
      const obid = this.$cookie.get("onebeerleft-login");
      console.log("getcellar" + obid);
      if (obid.length > 0) {
        const request = new Request(
          this.$apihost + "/onebeerleft.OneBeerLeftService/AddBeer",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              oblid: obid,
              cellarName: this.cName,
              quantity: 1,
              bid: this.beerId,
            }),
          }
        );
        try {
          const res = await fetch(request);
          console.log("ADD " + JSON.stringify(res));
          this.getCellar(this.cName);
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
    },
    async getCellar(cellarName) {
      this.loading = true;
      const obid = this.$cookie.get("onebeerleft-login");
      console.log("getcellar" + obid);
      if (obid.length > 0) {
        const request = new Request(
          this.$apihost + "/onebeerleft.OneBeerLeftService/GetCellar",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              oblid: obid,
              cellarName: cellarName,
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          console.log("GOT " + JSON.stringify(data))
          if (data.cellars.length > 0) {
             this.beers = data.cellars[0].beers;
             this.cellarType = data.cellars[0].exitStrategy;
             this.exitCellar = data.cellars[0].exitCellar;
             this.config.placeholder = data.cellars[0].exitStrategy;
             this.configExit.placeholder = data.cellars[0].exitCellar;
             console.log("HERE " + JSON.stringify(data.cellars[0].exitStrategy))
          }
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
    },
  },
};
</script>