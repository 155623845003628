<template>
  <div id="pull">
    <div v-if="this.$store.state.loggedIn" id="hide">
      <cellars />
    </div>
    <div v-else id="hide">This is One Beer Left</div>
  </div>
</template>

<script>
import Cellars from "./Cellars.vue";
export default {
  name: "pull",
  components: {
    Cellars,
  },
};
</script>