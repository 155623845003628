<template>
  <div v-if="this.$store.state.loggedIn" id="hide">
    <div v-if="cellars.length > 0" id="hide">
      <table border="0" width="90%" class="center">
        <tr><th>Cellar</th><th>Beers</th></tr>
        <tr v-for="item in cellars" :key="item.cellarName">
          <td><a v-bind:href="'/cellar/'+item.cellarName">{{ item.cellarName }}</a></td>
          <td>{{ item.beers.length }}</td>
          <td><button v-on:click="deleteCellar(item.cellarName)">Delete</button></td>
        </tr>
      </table>
      <br />
      <input v-model="cellarName" placeholder="" /><button
        v-on:click="addCellar"
      >
        Add Cellar
      </button>
    </div>
  </div>
  <div v-else id="hide">Please login to continue.</div>
</template>

<style scoped>
</style>

<script>
export default {
  data() {
    return {
      cellars: [],
      cellarName: "",
    };
  },
  beforeMount() {
    this.getCellars();
  },
  methods: {
    deleteCellar: function(cellarName) {
       this.deleteCellarAsync(cellarName);
    },
    addCellar: function () {
      this.addCellarAsync(this.cellarName);
    },
     async deleteCellarAsync(cellarName) {
       const obid = this.$cookie.get("onebeerleft-login");
      console.log("what" + obid);
      if (obid.length > 0) {
        var mbody = JSON.stringify({
          oblid: obid,
          cellarName: cellarName,
        });
        const request = new Request(
          this.$apihost + "/onebeerleft.OneBeerLeftService/DeleteCellar",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: mbody,
          }
        );
        try {
          console.log("BODY: " + mbody);
         await fetch(request);
                this.getCellars();
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
     },
    async addCellarAsync(cellarName) {
      const obid = this.$cookie.get("onebeerleft-login");
      console.log("what" + obid);
      if (obid.length > 0) {
        var mbody = JSON.stringify({
          oblid: obid,
          cellar: { cellarName: cellarName, beers: [] },
        });
        const request = new Request(
          this.$apihost + "/onebeerleft.OneBeerLeftService/SaveCellar",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: mbody,
          }
        );
        try {
          console.log("BODY: " + mbody);
          await fetch(request);
          this.getCellars();
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
    },
    async getCellars() {
      this.loading = true;
      const obid = this.$cookie.get("onebeerleft-login");
      console.log("getcellar" + obid);
      if (obid.length > 0) {
        const request = new Request(
          this.$apihost + "/onebeerleft.OneBeerLeftService/GetCellar",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              oblid: obid,
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          this.cellars = data.cellars;
          console.log("Got " + this.cellars.length + " cellars")
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
    },
  },
};
</script>