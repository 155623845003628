<template>
  <div id="app">
    <div id="header">
      <div id="logo" />
      <div id="text">
        <h1>
          <a href="#" v-bind:class="redirect" @click="home">One Beer Left</a>
        </h1>
        <Login />
      </div>
    </div>
    <div id="details">
      <router-view />
    </div>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
export default {
  components: {
    Login,
  },
  methods: {
    home() {
      window.location.href = "/";
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  background-color: #d4efe1;
}
ul {
  margin-left: 5%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#header {
  background-color: #69c1b7;
  height: 85px;
}

#details {
  padding: 20px;
}

#logo {
  height: 70px;
  width: 70px;
  margin: 5px;
  float: left;
  border: 1px solid black;
}
#text {
  padding: 20px;
  background-color: #69c1b7;
}

#app h1 {
  font-family: "Staatliches", cursive;
  font-weight: 400;
  display: inline;
  float: left;
  background-color: #69c1b7;
}

#app h2 {
  display: inline;
  text-align: right;
  float: right;
  background-color: #69c1b7;
}
#text a {
  display: inline;
  text-decoration: none;

  background-color: #69c1b7;
}
</style>