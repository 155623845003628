<template>
  <div v-if="this.$store.state.loggedIn" id="hide">
    <h2>
      <a href="#" v-bind:class="redirect" @click="logout"> Logout</a>
    </h2>
  </div>
  <div v-else id="hide">
    <h2><a href="#" v-bind:class="redirect" @click="redirect">Login</a></h2>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  mounted() {
    this.checkCookie();
  },
  methods: {
    checkCookie() {
      var cookie = this.$cookie.get("onebeerleft-login");
      console.log("CODE: " + cookie);
      if (cookie) {
        this.$store.commit("login");
      } else {
        this.$store.commit("logout");
      }
    },
    logout() {
      this.$cookie.delete("onebeerleft-login");
      this.$store.commit("logout");
      window.location.href = "/";
    },
    async redirect() {
      const request = new Request(
        this.$apihost + "/onebeerleft.OneBeerLeftService/GetURL",
        {
          method: "POST",
          mode: "cors",
          cache: "default",
        }
      );
      var res = await fetch(request);
      var data = await res.json();
      console.log(data);
      var nurl = data.URL;
      window.location.href = nurl;
    },
  },
};
</script>
<style scoped>
h2 {
  display: inline;
  text-align: right;
  float: right;
  text-decoration: none;
  background-color: #69c1b7;
}
a {
  display: inline;
  text-decoration: none;
  background-color: #69c1b7;
}
</style>;
